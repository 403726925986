import UserFunctions from "@/classes/UserFunctions";

const helpersMixin = {
    methods:{
        portalLogout() {
            this.axios.post(this.$urls.auth.logout.url).then(() => {
                this.userDropdown = false;
                this.showSearch = false;
                UserFunctions.removeUserInfo(false, true).then(() => {
                    this.$router.replace('/').catch(() => {
                    });
                });
            }).catch(() => {
            });
        },
        clickApp(item) {
            this.$store.dispatch('portal/setApp', item).then(() => {
                this.$router.push({ name: 'portal.tracking', params: { type: 'app' } }).catch(() => {
                });
            });
        },
    }
};

export default helpersMixin;